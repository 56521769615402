import { autoinject, computedFrom, observable } from 'aurelia-framework';
import { StateApi } from '../../../services/state-api';
import {
    GetVulnerabilityResponse,
    OrderingType,
    QualysVessel,
    QualysVesselsApiClient,
    QualysVulnerabilitiesApiClient
} from '../../../services/cyber-api';
import { ISortModel } from '../components/sort/sort';
import { Router } from 'aurelia-router';
import { MultiRangeSlider } from '../../../components/multi-range-slider/multi-range-slider';
import { FilterNames } from '../models/filter-names';
import { UrlUtilities } from '../../../utilities/url-utilities';

@autoinject()
export class Vulnerabilities {
    private query?: string;
    private vulnerabilities: GetVulnerabilityResponse[] = [];
    @observable() public sortModel: ISortModel;
    private take: number = 25;
    private skip: number = 0;
    private vulnerabilitiesLoading: boolean = false;
    private FilterNames: typeof FilterNames = FilterNames;
    private riskFilterRangeSlider: MultiRangeSlider;
    private types: string[] = ['Confirmed', 'Potential'];
    private vessels: QualysVessel[] = undefined;
    private filters = {
        siteId: undefined,
        severity: undefined,
        riskRange: [0, 100],
        type: undefined,
    };
    private filterDefaults = {
        siteId: undefined,
        severity: undefined,
        riskRange: [0, 100],
        type: undefined,
    };
    private series: Highcharts.SeriesColumnOptions[];
    private series2: Highcharts.SeriesColumnOptions[];
    private options: Highcharts.Options = {
        chart: {
            zooming: {
                type: null
            },
            // height: '100%', // Makes the chart fill the container's height
            // width: '100%'   // Makes the chart fill the container's width
        },
        legend: {
            enabled: false
        },
        xAxis: {
            type: 'category',
            labels: {
                formatter() {
                    // Access the corresponding y-value for this x-axis index
                    const series = this.axis.series[0]; // Assumes single series
                    const point = series.data[this.pos]; // Get the data point
                    return point ? point.y.toString() : '';
                }
            }
        },
        yAxis: {
            opposite: true,
            tickInterval: 1,
            gridLineWidth: 0,
            labels: {
                enabled: false
            },
            title: {
                text: ''
            }
        },
        plotOptions: {
            series: {
                borderWidth: 0,
                dataLabels: {
                    enabled: false,
                    format: '{point.y:.f}'
                },
            },
            column: {
                tooltip: {
                    headerFormat: '',
                    pointFormat: '{point.name}'
                },
            }
        },
        responsive: {
            rules: [{
                condition: {
                    maxWidth: 500
                },
                chartOptions: {
                    legend: {
                        align: 'center',
                        verticalAlign: 'bottom',
                        layout: 'horizontal'
                    },
                    yAxis: {
                        labels: {
                            align: 'left',
                            x: 0,
                            y: -5
                        },
                        title: {
                            text: null
                        }
                    },
                    subtitle: {
                        text: null
                    },
                    credits: {
                        enabled: false
                    }
                }
            }]
        },
    };

    private totalVulnerabilities: number | undefined = undefined;
    private affectedAssets: number | undefined = undefined;
    private totals: { key: string, value: number, color: string }[] = [];

    private chartColors = [
        '#F7CE46',
        '#F19E38',
        '#ED702D',
        '#EB4826',
        '#E30613'
    ];

    constructor(
        private qualysVulnerabilitiesApiClient: QualysVulnerabilitiesApiClient,
        private qualysVesselsApiClient: QualysVesselsApiClient,
        private state: StateApi,
        private router: Router
    ) {
    }

    private async activate(params: any): Promise<void> {
        this.query = params.query;

        this.setFiltersFromParams(params);

        this.sortModel = {
            direction: 'desc',
            field: 'ExternalReference',
        };

        this.retrieveStats();
        this.retrieveVessels();
    }

    private setFiltersFromParams(params: any): void {
        this.filters.siteId = params.site ? params.site : this.filterDefaults.siteId;
        this.filters.severity = params.severity ? params.severity : this.filterDefaults.severity;
        this.filters.riskRange = params.risk ? params.risk.split(',').map(Number) : this.filterDefaults.riskRange;
        this.filters.type = params.type ? params.type : this.filterDefaults.type;
    }

    private async retrieveStats(): Promise<void> {
        const chartData = await this.qualysVulnerabilitiesApiClient.getQualysVulnerabilityDetectionStatistics(this.state.company());
        if (!chartData) {
            this.series = [];
            this.series2 = [];
            this.totals = [];
            this.affectedAssets = undefined;
            this.totalVulnerabilities = undefined;
            return;
        }
        this.affectedAssets = chartData.hostExternalReferences.length;
        this.totalVulnerabilities = chartData.count;

        // totals should be a sum of confirmedCount and potentialCount
        this.totals = Object.entries(chartData.confirmedCount).map(([key, value], index) => {
            return {
                key,
                value: value + chartData.potentialCount[key],
                color: this.chartColors[index]
            };
        });

        this.series = this.mapSeries(chartData.confirmedCount);
        this.series2 = this.mapSeries2(chartData.potentialCount);
    }

    private async retrieveVessels(): Promise<void> {
        this.vessels = await this.qualysVesselsApiClient.getQualysVessels(this.state.company());
    }

    private async search(searchValue: string): Promise<void> {
        this.vulnerabilitiesLoading = true;

        this.query = searchValue;
        await this.resetVulnerabilities();

        this.vulnerabilitiesLoading = false;
    }

    private async resetVulnerabilities(): Promise<void> {
        this.vulnerabilities = [];
        this.skip = 0;
        await this.fetchVulnerabilities(0, true, false);
    }

    private async fetchVulnerabilities(topIndex: number, isAtBottom: boolean, isAtTop: boolean): Promise<void> {
        // Only fetch more when scroll position is at the bottom
        if (!isAtBottom) return;

        // When at the end of the list and no more data is available, short-circuit as there's nothing left
        // to fetch
        // if (this.totalCount === this.quarantines.length) return;
        this.vulnerabilitiesLoading = true;
        const results = await this.qualysVulnerabilitiesApiClient.getQualysVulnerabilities(
            this.state.company(),      // company
            this.skip,                 // skip
            this.take,                 // take
            this.query,                // query
            this.filters.siteId,       // site id
            this.filters.severity,     // severity
            this.filters.riskRange[0], // fromRisk
            this.filters.riskRange[1], // toRisk
            this.filters.type,         // type
            this.sortModel.field,      // sortField
            this.sortModel.direction,  // sortDirection
            OrderingType.AlphaNumeric, // orderingType
        );

        if (!results) {
            this.vulnerabilitiesLoading = false;
            return;
        }

        this.vulnerabilities = this.vulnerabilities.concat(results);

        this.skip += this.take;

        this.vulnerabilitiesLoading = false;
    }

    private async sortModelChanged(): Promise<void> {
        await this.search(this.query);
    }

    private filterChanged(): void {
        this.setUrlFilterParams();

        this.search(this.query);
    }

    private severityChanged(severity: number): void {
        if (this.filters.severity === severity)
            severity = undefined;

        this.filters.severity = severity;

        this.setUrlFilterParams();

        this.search(this.query);
    }

    private riskFilterChange = async (range: number[]): Promise<void> => {
        this.filters.riskRange = range;

        this.setUrlFilterParams();

        await this.search(this.query);
    };

    private clearFilter(filter: FilterNames): void {
        switch (filter) {
            case FilterNames.SiteId:
                this.filters.siteId = this.filterDefaults.siteId;
                break;
            case FilterNames.Severity:
                this.filters.severity = this.filterDefaults.severity;
                break;
            case FilterNames.Risk:
                this.filters.riskRange = this.filterDefaults.riskRange;
                if (this.riskFilterRangeSlider)
                    this.riskFilterRangeSlider.reset();
                break;
            case FilterNames.VulnerabilityType:
                this.filters.type = this.filterDefaults.type;
                break;
        }

        this.search(this.query);

        this.setUrlFilterParams();
    }

    private clearFilters(): void {
        this.filters.siteId = this.filterDefaults.siteId;

        this.filters.severity = this.filterDefaults.severity;

        this.filters.riskRange = this.filterDefaults.riskRange;
        if (this.riskFilterRangeSlider)
            this.riskFilterRangeSlider.reset();

        this.filters.type = this.filterDefaults.type;

        this.search(this.query);

        this.setUrlFilterParams();
    }

    @computedFrom('filters.siteId', 'filters.severity', 'filters.riskRange', 'filters.type')
    private get hasActiveFilters(): boolean {
        return this.filters.siteId
            || this.filters.severity
            || this.filters.riskRange[0] !== this.filterDefaults.riskRange[0]
            || this.filters.riskRange[1] !== this.filterDefaults.riskRange[1]
            || this.filters.type;
    }

    private setUrlFilterParams(): void {
        const route = 'vulnerability-scanner/vulnerabilities';

        const params = new URLSearchParams();

        if (this.filters.siteId)
            params.append('site', this.filters.siteId);

        if (this.filters.severity)
            params.append('severity', this.filters.severity);

        if (this.filters.riskRange[0] !== this.filterDefaults.riskRange[0]
            || this.filters.riskRange[1] !== this.filterDefaults.riskRange[1]) {
            params.append('risk', this.filters.riskRange.toString());
        }

        if (this.filters.type)
            params.append('type', this.filters.type);

        UrlUtilities.setUrlParams(this.router, route, params);
    }

    private mapSeries(data: { [key: string]: number; }): Highcharts.SeriesColumnOptions[] {
        const pointWidth = 76;
        const series = Object.entries(data);
        return [
            {
                name: 'crit',
                data:
                    series.map(([key, value], index) => {
                        return {
                            color: this.chartColors[index],
                            name: 'Severity ' + key,
                            y: value,
                            pointWidth
                        };
                    }),
                point: {
                    events: {
                        click: (event: any): boolean => {
                            // Retrieve the severity from the slice name
                            const sliceName: string = event.point.name.toLowerCase();
                            this.filters.severity = sliceName.split(' ')[1];
                            this.filters.type = 'Confirmed';
                            this.filterChanged();

                            return true;
                        }
                    }
                },
                cursor: 'pointer',
                type: 'column'
            }
        ];
    }

    private mapSeries2(data: { [key: string]: number; }): Highcharts.SeriesColumnOptions[] {
        const pointWidth = 76;
        const series = Object.entries(data);
        return [
            {
                name: 'crit',
                data:
                    series.map(([key, value], index) => {
                        return {
                            color: this.chartColors[index],
                            name: 'Severity ' + key,
                            y: value,
                            pointWidth
                        };
                    }),
                point: {
                    events: {
                        click: (event: any): boolean => {
                            // Retrieve the severity from the slice name
                            const sliceName: string = event.point.name.toLowerCase();
                            this.filters.severity = sliceName.split(' ')[1];
                            this.filters.type = 'Potential';
                            this.filterChanged();

                            return true;
                        }
                    }
                },
                cursor: 'pointer',
                type: 'column'
            }
        ];
    }
}
