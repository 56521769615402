import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-framework';
import {
    IQualysAsset,
    IQualysVessel,
    QualysAssetsApiClient,
    QualysVesselsApiClient,
    QualysVulnerabilitiesApiClient,
    QualysVulnerabilityBySiteIndexResult,
} from '../../../../services/cyber-api';
import { StateApi } from '../../../../services/state-api';
import { DownloadUtilities } from '../../../../utilities/download-utilities';
import Swal from 'sweetalert2';

@autoinject()
export class Vessel {
    private siteId: string;
    private vessel: IQualysVessel;
    private vesselAssets: IQualysAsset[];
    private lastScannedDate: Date;
    private vulnerabilitiesCount: number;
    private vulnerabilitiesPerSeverity: ISeverityVulnerabilityCount[];
    private topAssets: ITopAsset[] = [];

    private downloadScanLoading: boolean = false;

    private series: Highcharts.SeriesOptionsType[];
    private options: Highcharts.Options = {
        chart: {
            zooming: {
                type: null
            }
        },
        legend: {
            enabled: false
        },
        plotOptions: {
            pie: {
                size: '100%',
                dataLabels: {
                    enabled: false,
                },
                tooltip: {
                    pointFormat: '{point.name}: <b>{point.y}</b>'
                }
            },

        }
    };

    private constructor(
        private router: Router,
        private qualysVesselsApiClient: QualysVesselsApiClient,
        private qualysAssetsApiClient: QualysAssetsApiClient,
        private qualysVulnerabilitiesApiClient: QualysVulnerabilitiesApiClient,
        private state: StateApi
    ) {
    }

    private async activate(params: { id: string }): Promise<void> {
        this.siteId = params.id;

        if (!this.siteId) {
            this.router.navigateBack();
        }

        this.qualysVesselsApiClient.getQualysVesselBySiteId(
            this.siteId,          // asset site id
            this.state.company()    // company
        ).then((vessel) => {
            this.vessel = vessel;
            this.qualysAssetsApiClient.getQualysAssets(
                this.state.company(), // company
                0,                    // skip
                1000,                 // take
                undefined,            // query
                this.siteId,          // site id
            ).then((assets) => {
                this.vesselAssets = assets;
                // Extract the last scan date
                if (this.vesselAssets.length > 0) {
                    const sorted = this.vesselAssets.sort((a, b) => {
                        return new Date(b.lastVmScannedDate).getTime() - new Date(a.lastVmScannedDate).getTime();
                    });
                    this.lastScannedDate = sorted[0].lastVmScannedDate;
                }

                this.qualysVulnerabilitiesApiClient.getVulnerabilityDetectionStatisticsPerSite(
                    this.siteId,   // site id
                    this.state.company(), // company
                ).then((vulnerabilities) => {
                    // count all unique vulnerabilityDetections
                    this.vulnerabilitiesCount = vulnerabilities.map(x => x.externalReference).length;

                    // Count vulnerabilities per severity
                    this.vulnerabilitiesPerSeverity = vulnerabilities.reduce((acc, detection) => {
                        const severity = detection.severity;
                        const existing = acc.find((x) => x.severity === severity);
                        if (existing)
                            existing.count++;
                        else
                            acc.push({ severity, count: 1 });
                        return acc;
                    }, []).sort((a, b) => b.severity - a.severity);

                    // within vulnerabilities, count the number of unique vulnerability.hostExternalReferences
                    // and the number of times they occur.
                    vulnerabilities.forEach((vulnerability) => {
                        vulnerability.hostExternalReferences.forEach((hostExternalReference) => {
                            const asset = this.topAssets.find((x) => x.id === hostExternalReference);
                            if (asset) {
                                asset.count++;
                            } else {
                                this.topAssets.push({
                                    id: hostExternalReference,
                                    count: 1,
                                    name: this.vesselAssets.find((x) => x.externalReference === hostExternalReference)?.name,
                                    ip: this.vesselAssets.find((x) => x.externalReference === hostExternalReference)?.ip
                                });
                            }
                        });
                    });

                    // Sort the top assets by vulnerability detection count
                    this.topAssets = this.topAssets.sort((a, b) => b.count - a.count).slice(0, 5);

                    const chartData = vulnerabilities.sort((a, b) => b.detectionCount - a.detectionCount).slice(0, 5);
                    this.series = this.mapSeries(chartData);
                });
            });
        });

    }

    private async DownloadLatestScanResult(): Promise<void> {
        this.downloadScanLoading = true;

        Swal.fire({ title: 'Downloading last scan result...', html: '<i class="fal fa-spinner-third fa-spin fa-3x my-3"></i>', showConfirmButton: false });
        let file = await this.qualysVesselsApiClient.getQualysVesselLatestScanReportBySiteId(this.siteId, this.state.company());
        DownloadUtilities.processDownload(file.data, `${this.siteId}_scan_report_${Date.now()}.csv`); // since filename is not provided, we need to set it here
        Swal.close();

        this.downloadScanLoading = false;
    }

    private mapSeries(chartData: QualysVulnerabilityBySiteIndexResult[]): Highcharts.SeriesOptionsType[] {
        const colors = ['#E30613', '#EB4826', '#ED702D', '#F19E38', '#F7CE46'];
        return [
            {
                name: 'Vuln',
                data: chartData.map((x, i) => {
                    return {
                        name: '#' + x.externalReference,
                        y: x.detectionCount,
                        color: colors[i],
                        description: x.title
                    };
                }),
                point: {
                    events: {
                        click: (event: any): boolean => {
                            // Retrieve the name from the slice name
                            const sliceName: string = event.point.name.toLowerCase();
                            const vulnerabilityId = sliceName.replace('#', '');

                            this.router.navigateToRoute('vulnerability-scanner/vulnerabilities/vulnerability', {
                                id: vulnerabilityId,
                                siteId: this.siteId
                            });

                            return true;
                        }
                    }
                },
                cursor: 'pointer',
                type: 'pie'
            }
        ];
    }
}

interface ITopAsset {
    id: string;
    name: string;
    ip: string;
    count: number;
}

interface ISeverityVulnerabilityCount {
    severity: number;
    count: number;
}
