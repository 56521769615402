import { containerless, observable } from 'aurelia-framework';
import { bindable } from 'aurelia-templating';

@containerless()
export class Toolbar {
    @bindable() private search: (args: { searchValue: string }) => void;
    @bindable() private searchValue: string;

    private searchValueChanged(): void {
        if (this.search)
            this.search({ searchValue: this.searchValue });
    }

    private clearSearchValue(): void {
        this.searchValue = undefined;
    }
}
