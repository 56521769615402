import { autoinject } from 'aurelia-framework';
import { RouteConfig, Router, RouterConfiguration } from 'aurelia-router';
import { PLATFORM } from 'aurelia-pal';

@autoinject()
export class VulnerabilityScanner {
    private maps(): RouteConfig[] {
        return [
            {
                route: ['', 'vessels'],
                href: 'vulnerability-scanner/vessels',
                name: 'vulnerability-scanner/vessels',
                moduleId: PLATFORM.moduleName('./vessels/vessels'),
                nav: true,
                title: 'Vessels',
                settings: { icon: 'fal fa-ship fa-fw', breadcrumb: true, showTitle: true },
            },
            {
                route: 'vessels/:id',
                href: 'vulnerability-scanner/vessels/vessel',
                name: 'vulnerability-scanner/vessels/vessel',
                moduleId: PLATFORM.moduleName('./vessels/vessel/vessel'),
                nav: false,
                title: 'Vessel',
                settings: { icon: 'fal fa-ship fa-fw', breadcrumb: true, showTitle: true }
            },
            {
                route: 'assets',
                href: 'vulnerability-scanner/assets',
                name: 'vulnerability-scanner/assets',
                moduleId: PLATFORM.moduleName('./assets/assets'),
                nav: true,
                title: 'Assets',
                settings: { icon: 'fal fa-building-shield fa-fw', breadcrumb: true, showTitle: true }
            },
            {
                route: 'software',
                href: 'vulnerability-scanner/software',
                name: 'vulnerability-scanner/software',
                moduleId: PLATFORM.moduleName('./software/software'),
                nav: true,
                title: 'Software',
                settings: { icon: 'fal fa-compact-disc fa-fw', breadcrumb: true, showTitle: true }
            },
            {
                route: 'vulnerabilities',
                href: 'vulnerability-scanner/vulnerabilities',
                name: 'vulnerability-scanner/vulnerabilities',
                moduleId: PLATFORM.moduleName('./vulnerabilities/vulnerabilities'),
                nav: true,
                title: 'Vulnerabilities',
                settings: { icon: 'fal fa-shield-exclamation fa-fw', breadcrumb: true, showTitle: true }
            },
            {
                route: 'vulnerabilities/:id',
                href: 'vulnerability-scanner/vulnerabilities/vulnerability',
                name: 'vulnerability-scanner/vulnerabilities/vulnerability',
                moduleId: PLATFORM.moduleName('./vulnerabilities/vulnerability/vulnerability'),
                nav: false,
                title: 'Vulnerability',
                settings: { icon: 'fal fa-shield-exclamation fa-fw', breadcrumb: true, showTitle: true }
            },

            // Include the not-found route in the routes so we can also manually navigate to it
            this.unknownRoute,
        ] as RouteConfig[];
    }

    private unknownRoute: RouteConfig = {
        route: 'not-found',
        name: 'unknown',
        title: 'Not Found',
        moduleId: PLATFORM.moduleName('views/not-found/not-found'),
        settings: { allowAnonymous: true }
    };

    private configureRouter(config: RouterConfiguration, router: Router): void {
        config.options.pushState = true;
        config.map(this.maps());
        config.mapUnknownRoutes(this.unknownRoute);
    }
}
