import { bindable } from 'aurelia-templating';
import { containerless } from 'aurelia-framework';

@containerless()
export class Sort {
    @bindable() public field: string;
    @bindable() public model: ISortModel = {
        direction: undefined,
        field: undefined,
    };

    public changeSortDirection(): void {
        if (this.model.field !== this.field) {
            this.model.direction = undefined;
            this.model.field = this.field;
        }

        switch (this.model.direction) {
            case 'asc':
                this.model.direction = 'desc';
                break;
            case 'desc':
                this.model.direction = undefined;
                break;
            case undefined:
            default:
                this.model.direction = 'asc';
        }

        this.model = { ...this.model };
    }
}

type SortDirection = 'asc' | 'desc' | undefined;

export interface ISortModel {
    direction: SortDirection;
    field: string;
}
