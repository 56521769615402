import { bindable } from 'aurelia-templating';
import { containerless } from 'aurelia-framework';

@containerless()
export class SeverityDisplay {
    @bindable() public value: number = 0;
    @bindable() public selectable: boolean = false;
    @bindable() public onSelect: (args: { severity: SeverityValue }) => void;
    private severities: SeverityValue[] = [1, 2, 3, 4, 5];

    private selectSeverity(severity: SeverityValue): void {
        if (!this.selectable)
            return;

        if (this.onSelect)
            this.onSelect({ severity });
    }
}

type SeverityValue = 1 | 2 | 3 | 4 | 5;
