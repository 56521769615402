import { autoinject, computedFrom, observable } from 'aurelia-framework';
import { OrderingType, QualysVessel, QualysVesselsApiClient, } from '../../../services/cyber-api';
import { StateApi } from '../../../services/state-api';
import { ISortModel } from '../components/sort/sort';
import { MultiRangeSlider } from '../../../components/multi-range-slider/multi-range-slider';
import { Router } from 'aurelia-router';
import { FilterNames } from '../models/filter-names';
import { UrlUtilities } from '../../../utilities/url-utilities';

@autoinject()
export class Vessels {
    public query?: string;
    public viewMode: ViewMode = 'grid';
    public vessels: QualysVessel[] = undefined;
    private vesselsLoading: boolean = false;
    @observable() public sortModel: ISortModel;

    private FilterNames: typeof FilterNames = FilterNames;
    private averageAssetRiskFilterRangeSlider: MultiRangeSlider;
    private highestAssetRiskFilterRangeSlider: MultiRangeSlider;
    private filters = {
        averageAssetRiskRange: [0, 100],
        highestAssetRiskRange: [0, 100],
    };
    private filterDefaults = {
        averageAssetRiskRange: [0, 100],
        highestAssetRiskRange: [0, 100],
    };

    constructor(
        private qualysVesselsApi: QualysVesselsApiClient,
        private state: StateApi,
        private router: Router,
    ) {
    }

    private async activate(params: any): Promise<void> {
        this.query = params.query;

        this.setFiltersFromParams(params);

        this.sortModel = {
            direction: 'asc',
            field: 'Title',
        };
    }

    private setFiltersFromParams(params: any): void {
        if (params.averageRisk) {
            this.filters.averageAssetRiskRange = params.averageRisk.split(',').map(Number);
        } else {
            this.filters.averageAssetRiskRange = this.filterDefaults.averageAssetRiskRange;
        }

        if (params.highestRisk) {
            this.filters.highestAssetRiskRange = params.highestRisk.split(',').map(Number);
        } else {
            this.filters.highestAssetRiskRange = this.filterDefaults.highestAssetRiskRange;
        }
    }

    private async search(searchValue: string): Promise<void> {
        this.vesselsLoading = true;
        this.vessels = undefined;

        this.query = searchValue;

        this.vessels = await this.qualysVesselsApi.getQualysVessels(
            this.state.company(),                   // company
            this.query,                             // query
            this.filters.highestAssetRiskRange[0],  // fromHighestRisk
            this.filters.highestAssetRiskRange[1],  // toHighestRisk
            this.filters.averageAssetRiskRange[0],  // fromAverageRisk
            this.filters.averageAssetRiskRange[1],  // toAverageRisk
            this.sortModel.field,                   // sortField
            this.sortModel.direction,               // sortDirection
            OrderingType.AlphaNumeric,              // orderingType
        );
        this.vesselsLoading = false;
    }

    private async sortModelChanged(): Promise<void> {
        await this.search(this.query);
    }

    private setViewMode(newViewMode: ViewMode): void {
        this.viewMode = newViewMode;
    }

    private averageAssetRiskFilterChange = async (range: number[]): Promise<void> => {
        this.filters.averageAssetRiskRange = range;

        this.setUrlFilterParams();

        await this.search(this.query);
    };

    private highestAssetRiskFilterChange = async (range: number[]): Promise<void> => {
        this.filters.highestAssetRiskRange = range;

        await this.search(this.query);

        this.setUrlFilterParams();
    };

    private clearFilter(filter: FilterNames): void {
        switch (filter) {
            case FilterNames.AverageAssetRisk:
                this.filters.averageAssetRiskRange = this.filterDefaults.averageAssetRiskRange;
                if (this.averageAssetRiskFilterRangeSlider)
                    this.averageAssetRiskFilterRangeSlider.reset();
                break;
            case FilterNames.HighestAssetRisk:
                this.filters.highestAssetRiskRange = this.filterDefaults.highestAssetRiskRange;
                if (this.highestAssetRiskFilterRangeSlider)
                    this.highestAssetRiskFilterRangeSlider.reset();
                break;
        }

        this.search(this.query);

        this.setUrlFilterParams();
    }

    private clearFilters(): void {
        this.filters.averageAssetRiskRange = this.filterDefaults.averageAssetRiskRange;
        if (this.averageAssetRiskFilterRangeSlider)
            this.averageAssetRiskFilterRangeSlider.reset();

        this.filters.highestAssetRiskRange = this.filterDefaults.highestAssetRiskRange;
        if (this.highestAssetRiskFilterRangeSlider)
            this.highestAssetRiskFilterRangeSlider.reset();

        this.search(this.query);

        this.setUrlFilterParams();
    }

    @computedFrom('filters.averageAssetRiskRange', 'filters.highestAssetRiskRange')
    private get hasActiveFilters(): boolean {
        return this.filters.averageAssetRiskRange[0] !== this.filterDefaults.averageAssetRiskRange[0]
            || this.filters.averageAssetRiskRange[1] !== this.filterDefaults.averageAssetRiskRange[1]
            || this.filters.highestAssetRiskRange[0] !== this.filterDefaults.highestAssetRiskRange[0]
            || this.filters.highestAssetRiskRange[1] !== this.filterDefaults.highestAssetRiskRange[1];
    }

    private setUrlFilterParams(): void {
        const route = 'vulnerability-scanner/vessels';

        const params = new URLSearchParams();

        if (this.filters.averageAssetRiskRange[0] !== this.filterDefaults.averageAssetRiskRange[0]
            || this.filters.averageAssetRiskRange[1] !== this.filterDefaults.averageAssetRiskRange[1]) {
            params.append('averageRisk', this.filters.averageAssetRiskRange.toString());
        }
        if (this.filters.highestAssetRiskRange[0] !== this.filterDefaults.highestAssetRiskRange[0]
            || this.filters.highestAssetRiskRange[1] !== this.filterDefaults.highestAssetRiskRange[1]) {
            params.append('highestRisk', this.filters.highestAssetRiskRange.toString());
        }

        UrlUtilities.setUrlParams(this.router, route, params);
    }
}

type ViewMode = 'grid' | 'table';


