import { bindable } from 'aurelia-templating';
import { ISortModel, Sort } from '../sort/sort';

export class SortableHeader {
    @bindable() public field: string;
    @bindable() public sortModel: ISortModel;

    private sortComponent: Sort;

    private changeSortDirection(): void {
        this.sortComponent.changeSortDirection();
    }
}
