import { bindable } from 'aurelia-templating';

export class SeverityDistribution {
    @bindable() public severities: number[];
    private totalCount: number;

    public attached(): void {
        this.totalCount = this.severities.reduce((acc, curr) => acc + curr, 0);
    }

    public getWidthPercentage(severityCount: number): number {
        return severityCount / this.totalCount * 100;
    }
}
