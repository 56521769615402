import { bindable } from 'aurelia-templating';
import $ from 'jquery';

export class FiltersDropdown {
    @bindable() private hasActiveFilters: boolean;
    @bindable() private clearFilters: () => void;
    private dropdownContainerElement: HTMLDivElement;

    private attached(): void {
        const $dropdown = $(this.dropdownContainerElement);

        $dropdown.on('click', (event) => {
            // When the dropdown is clicked, set closable to true to allow the dropdown to be closed by clicking outside of it
            if ($(event.target).closest('.filters-dropdown-toggle').length)
                $dropdown.data('closable', true);
            else
                $dropdown.data('closable', false);
        });

        $dropdown.on('hide.bs.dropdown', (event) => {
            const closable: boolean = $dropdown.data('closable');

            // Reset closable to true to allow outside clicks to close the dropdown
            $dropdown.data('closable', true);

            return closable;
        });
    }

    private callClearFilters(): void {
        if (this.clearFilters)
            this.clearFilters();
    }

    private detached(): void {
        const $dropdown = $(this.dropdownContainerElement);
        $dropdown.off('click');
        $dropdown.off('hide.bs.dropdown');
    }
}
