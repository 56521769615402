import {
    IGetVulnerabilityResponse,
    IQualysAsset,
    OrderingType,
    QualysAssetsApiClient,
    QualysVulnerabilitiesApiClient
} from '../../../../services/cyber-api';
import { StateApi } from '../../../../services/state-api';
import { autoinject, observable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Toast } from '../../../../utilities/toast';
import { ISortModel } from '../../components/sort/sort';

@autoinject()
export class Vulnerability {
    private vulnerability: IGetVulnerabilityResponse;
    private affectedAssets: IQualysAsset[];
    @observable() public assetsSortModel: ISortModel = {
        direction: 'desc',
        field: 'ExternalReference',
    };

    public constructor(
        private state: StateApi,
        private router: Router,
        private vulnerabilitiesApi: QualysVulnerabilitiesApiClient,
        private assetsApi: QualysAssetsApiClient,
    ) {
    }

    public async activate(params: any): Promise<void> {
        const id = params.id;

        this.retrieveVulnerability(id)
            .then(() => this.retrieveAssets());
    }

    private async retrieveVulnerability(id: string): Promise<void> {
        this.vulnerability = await this.vulnerabilitiesApi.getQualysVulnerability(
            id,                  // id
            this.state.company() // company
        );

        if (!this.vulnerability) {
            Toast.error('Vulnerability could not be found, try again later');
            this.router.navigateToRoute('vulnerability-scanner/vulnerabilities');
        }
    }

    private async retrieveAssets(): Promise<void> {
        if (!this.vulnerability || !this.vulnerability.hostExternalReferences) {
            return;
        }

        this.affectedAssets = await this.assetsApi.getQualysAssets(
            this.state.company(),                      // company
            0,                                         // skip
            1000,                                      // take
            undefined,                                 // query
            undefined,                                 // siteId
            undefined,                                 // fromRisk
            undefined,                                 // toRisk
            undefined,                                 // operatingSystem
            undefined,                                 // software
            this.vulnerability.hostExternalReferences, // externalReferences
            this.assetsSortModel.field,                // sortField
            this.assetsSortModel.direction,            // sortDirection
            OrderingType.AlphaNumeric,                 // sortType
        );
    }

    private getVulnerabilityStatusForAsset(assetExternalId: string): string {
        if (!this.vulnerability || !this.vulnerability.statusByAssetExternalReference) {
            return null;
        }

        return this.vulnerability.statusByAssetExternalReference[assetExternalId];
    }

    private async assetsSortModelChanged(): Promise<void> {
        await this.retrieveAssets();
    }
}
